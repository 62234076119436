import React from "react"
import { graphql } from "gatsby"
import marked from "marked"
import Container from "components/Container"
import Layout from "components/Layout"
import Title from "components/Title"
import TitleBlock from "components/Blocks/TitleBlock"
import styles from "./PrivacyPolicy.module.css"
import altStyles from "../templates/WhatWeDo.module.css"
import HubspotForm from "components/HubspotForm"

// eslint-disable-next-line
export const PrivacyPolicyPage = ({ data, html, ...props }) => {

    return (
        <Layout {...props} {...data}>
            <div className={styles.preview}>
                <Container>
                    <div>
                        <Title className={`${altStyles.title} ${altStyles.titlesmall} ${styles.title}`}>
                            <span dangerouslySetInnerHTML={{ __html: data.title || "" }} />
                        </Title>
                        <div className={styles.body} dangerouslySetInnerHTML={{ __html: marked(html || "") }} />
                    </div>
                </Container>
            </div>

            {data.form && data.portalId ? (
                <div className={altStyles.grayBackground} id="contact-form">
                    <Container>
                        <div className={altStyles.titleContainer}>
                            <TitleBlock isLarger><span className={altStyles.preTitle}>Contact Us</span><span dangerouslySetInnerHTML={{ __html: data.formTitle || "" }} /></TitleBlock>
                        </div>
                        <HubspotForm id={data.form} portalId={data.portalId} />
                    </Container>
                </div>
            ) : null}
        </Layout>
    )
}

// eslint-disable-next-line
export const PrivacyPolicyPageWrapper = ({ data, ...props }) => (
    <PrivacyPolicyPage
        {...props}
        data={data.markdownRemark.frontmatter}
        html={data.markdownRemark.html}

    />
)

export default PrivacyPolicyPageWrapper

export const pageQuery = graphql`
  query PrivacyPolicyQuery {
    markdownRemark(frontmatter: { templateKey: { eq: "privacy-policy" } }) {
      html
      frontmatter {
        seo {
          title
          description
          canonical
          image {
            extension
            publicURL
            childImageSharp {
              fixed(width: 1200) {
                ...GatsbyImageSharpFixed_withWebp_noBase64
              }
            }
          }
        }
        title
        form
        portalId
        formTitle
      }
    }
  }
`
